import React from 'react';
import cc from 'classcat';
import AnimateHeight from 'react-animate-height';
import { useToggle } from 'react-use';

import useIsMobile from '@/common/hooks/useIsMobile.ts';

import IconChevronDown from '@/common/icons/tabler/chevron-down.svg';

import ShopCarsLinkItems from './ShopCarsLinkItems/index.tsx';

import styles from './styles.module.scss';

export type ShopCarsLinks = {
  title: string;
  link: string;
};

type ShopCarsBodies = {
  title: string;
  icon: React.FC<React.SVGAttributes<SVGElement>>;
};

interface Props {
  links: ShopCarsLinks[];
  bodies: ShopCarsBodies[];
}

export default function ShopCarsDropdown({ links, bodies }: Props) {
  const [isOpen, toggleOpen] = useToggle(false);
  const [isMobile] = useIsMobile(768);

  return (
    <div className={cc({ [styles.shopCars]: true, [styles.isOpen]: isOpen })}>
      <button
        type="button"
        onClick={toggleOpen}
        className={cc({ [styles.shopCarsButton]: true, [styles.CarsButton]: true, [styles.isOpen]: isOpen })}
        data-testid="shopCarsButton"
      >
        {ShopCarsDropdown.defaultMenuTitle}
        <IconChevronDown />
      </button>
      <div
        className={cc({
          [styles.carsList]: true,
          [styles.hideDivider]: !links.length || !bodies.length,
          [styles.isOpen]: isOpen,
        })}
        role={'list'}
        data-testid="dropdownlistCars"
      >
        <AnimateHeight duration={350} height={isOpen ? 'auto' : 0} className={styles.animationHeight}>
          <div className={cc({ [styles.carsListContainer]: true })}>
            {!!links.length && (
              <div className={styles.carsItems}>
                <p>{ShopCarsDropdown.defaultLinkText}</p>
                {links.map((item, index) => (
                  <ShopCarsLinkItems key={item.title} item={item} styles={styles} index={index} />
                ))}
              </div>
            )}
            <div className={styles.divider} />
            {!!bodies.length && (
              <div className={styles.carsTypes}>
                <p>{ShopCarsDropdown.defaultIconText}</p>
                {bodies.map((item) => (
                  <a
                    className={cc({
                      [styles.type]: true,
                      [styles.iconItem]: !!item.icon,
                    })}
                    href={'/cars?bodyTypes=' + item.title}
                    key={item.title}
                  >
                    <span>{React.createElement(item.icon)}</span>
                    {item.title}
                  </a>
                ))}
              </div>
            )}
          </div>
        </AnimateHeight>
      </div>
      {!isMobile && isOpen && (
        <div
          data-testid="backdrop"
          className={cc([styles.backdrop, styles.backdropCars])}
          onClick={toggleOpen}
          onKeyDown={(e) => e.key === 'Escape' && toggleOpen}
          role="presentation"
        />
      )}
    </div>
  );
}

ShopCarsDropdown.defaultMenuTitle = 'Shop Cars';
ShopCarsDropdown.defaultLinkText = 'Shop cars';
ShopCarsDropdown.defaultIconText = 'Browse by body style';
