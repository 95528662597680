import React from 'react';

import Button from '@/common/components/Button/index.tsx';

import { ShopCarsLinks } from '../shopCars.tsx';

const enum LinkItemType {
  ShopAllCars = 0,
}

interface LinkItemProps {
  item: ShopCarsLinks;
  styles: {
    readonly [key: string]: string;
  };
  index: LinkItemType;
}

const ShopCarsLinkItems: React.FC<LinkItemProps> = ({ item, styles, index }) => {
  if (index === LinkItemType.ShopAllCars) {
    return (
      <div className={styles.shopAllCarListItem}>
        <Button href={item.link} key={item.title} colour="brand" size="M" style="solid">
          {item.title}
        </Button>
      </div>
    );
  }

  return (
    <a className={styles.carsItem} href={item.link} key={item.title}>
      {item.title}
    </a>
  );
};
export default ShopCarsLinkItems;
