'use client';

import React from 'react';
import cc from 'classcat';
import { useToggle } from 'react-use';

import Logo from '@/common/components/Logo/index.tsx';
import useIsMobile from '@/common/hooks/useIsMobile.ts';

import IconMenu2 from '@/common/icons/tabler/menu-2.svg';
import IconX from '@/common/icons/tabler/x.svg';

import Navigation from './Navigation/index.tsx';
import RightMenu from './RightMenu/index.tsx';

import styles from './styles.module.scss';

const Header = () => {
  const [mobileMenuOpen, toggleMobileMenu] = useToggle(false);
  const [isMobile, isTablet] = useIsMobile(768, 1024, 1464);

  return (
    <header className={styles.header} data-active={mobileMenuOpen} data-testid="header">
      <div className={styles.inner}>
        <div className={styles.left}>
          <a href="/cars">
            <Logo />
          </a>

          <Navigation
            showDropdown={!isTablet || !isMobile}
            openMobileMenu={mobileMenuOpen}
            onClickAway={toggleMobileMenu}
          />
        </div>

        <div className={styles.right}>
          <RightMenu />
          <button
            className={cc([styles.mobileMenu, { [styles.mobileMenuActive]: mobileMenuOpen }])}
            onClick={() => toggleMobileMenu()}
            type="button"
            data-testid="mobileMenuButton"
            data-active={mobileMenuOpen}
            aria-label="open mobile menu"
          >
            {mobileMenuOpen ? <IconX /> : <IconMenu2 />}
          </button>
        </div>
      </div>
    </header>
  );
};
export default Header;
