/**
 * Contains all static content for site
 */

import CoupeIcon from '@/common/images/types/coupe.svg';
import UtilityIcon from '@/common/images/types/fourwheel.svg';
import HatchbackIcon from '@/common/images/types/hatchback.svg';
import SedanIcon from '@/common/images/types/sedan.svg';
import SuvIcon from '@/common/images/types/suv.svg';
import WagonIcon from '@/common/images/types/wagon.svg';

export const baseURL = `https://www.fleetcare.com.au`;

// Header data
export type NavLinks = {
  title: string;
  link: string;
};

export const fleetManagementLinks: NavLinks[] = [
  { title: 'Fleet Management', link: `${baseURL}/fleet-management` },
  { title: 'Fleet Finance', link: `${baseURL}/finance-and-Leasing` },
  { title: 'Telematics', link: `${baseURL}/telematics` },
];

export const novatedLeasingLinks: NavLinks[] = [
  { title: 'Novated Leasing', link: `${baseURL}/novated-leasing` },
  { title: 'Employer Solutions', link: `${baseURL}/novated-leasing/employer-solutions` },
  { title: 'Novated Lease Calcultor', link: `${baseURL}/calculator` },
  { title: 'Sell your car', link: `${baseURL}/sell-my-car` },
];

export const fleetFinanceLinks: NavLinks[] = [
  { title: 'Finance Options', link: `${baseURL}/finance-and-leasing` },
  { title: 'Operating Lease', link: `${baseURL}/finance-and-leasing/operating-lease` },
  { title: 'Car Trade In Service', link: `${baseURL}/sell-my-carleasing/operating-lease` },
];
export const aboutUsLinks: NavLinks[] = [
  { title: 'Corporate Overview', link: `${baseURL}/` },
  { title: 'Careers', link: `${baseURL}/about-us/corporate-overview` },
  { title: 'News', link: `${baseURL}/about-us/join-the-family` },
];

export const shopCarsLinks = [
  { title: 'Shop all cars', link: '/cars' },
  { title: 'New cars', link: '/cars?listingTypes=NEW' },
  { title: 'Used cars', link: '/cars?listingTypes=USED' },
  { title: 'Electric cars', link: '/cars?fuelTypes=Electric' },
];

export const shopCarsBodies = [
  { title: 'SUV', icon: SuvIcon },
  { title: 'Hatchback', icon: HatchbackIcon },
  { title: 'Utility', icon: UtilityIcon },
  { title: 'Coupe', icon: CoupeIcon },
  { title: 'Sedan', icon: SedanIcon },
  { title: 'Wagon', icon: WagonIcon },
];

// Footer data
export type StaticContent = {
  title: string;
  items?: StaticContentData[];
};

type StaticContentData = {
  title: string;
  link?: string;
};

export const Footer: StaticContent[] = [
  {
    title: 'Buy',
    items: [
      {
        title: 'All makes and models',
        link: '/cars',
      },
      {
        title: 'Used cars',
        link: '/cars?listingTypes=USED',
      },
    ],
  },
  {
    title: 'Sell',
    items: [
      {
        title: 'Get an instant valuation',
        link: `${baseURL}/sell-my-car`,
      },
    ],
  },
  {
    title: 'About us',
    items: [
      {
        title: 'Corporate overview',
        link: `${baseURL}/about-us/corporate-overview`,
      },
      {
        title: 'Join the family',
        link: `${baseURL}/about-us/join-the-family`,
      },
      {
        title: 'Fleettorque (News)',
        link: `${baseURL}/news-fleettorque`,
      },
    ],
  },
  {
    title: 'Support',
    items: [
      {
        title: 'Fleetcare Finder',
        link: `${baseURL}/finder`,
      },
      {
        title: 'Novated customer',
        link: `${baseURL}/novated-leasing/novated-lease-customer-support`,
      },
      {
        title: 'Fleetcare customer',
        link: `${baseURL}/fleet-management/fleet-customer-support`,
      },
      {
        title: 'Become a supplier',
        link: `${baseURL}/become-an-authorised-supplier`,
      },
      {
        title: 'Car hire',
        link: `${baseURL}/tools-and-resources/car-hire`,
      },
    ],
  },
];

// Telephone number
export const phoneNumber = '134 333';

// Email address
export const emailAddress = 'info@fleetcare.com.au';

// Footer social links
export const linkFb = 'https://www.facebook.com/FleetcareAU/';
export const linkLinkedin = 'https://www.linkedin.com/company/fleetcare';
export const linkInstagram = 'https://www.instagram.com/fleetcare_aus/';
export const linkYoutube = 'https://www.youtube.com/user/fleetcaretv';

// Helpful links: 404 page
export const helpfulLinks = [
  { text: 'Home', link: '/cars' },
  { text: 'Shop cars', link: '/cars' },
  { text: 'Locations', link: `${baseURL}/contact-us` },
  { text: 'Email help and support', link: `mailto:${emailAddress}` },
  { text: 'Shop Electric cars', link: '/cars?fuelTypes=Electric' },
  { text: 'Shop SUVs', link: '/cars?bodyTypes=SUV' },
];
