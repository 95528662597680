import React from 'react';

import FleetcareLogoDark from '@/common/icons/fleetcare-logo-dark.svg';
import FleetcareLogoLight from '@/common/icons/fleetcare-logo-light.svg';

import styles from './styles.module.scss';

export default function Logo() {
  return (
    <>
      <div className={styles.logoLight}>
        <FleetcareLogoLight />
      </div>
      <div className={styles.logoDark}>
        <FleetcareLogoDark />
      </div>
    </>
  );
}
