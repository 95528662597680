import React from 'react';

import useIsMobile from '@/common/hooks/useIsMobile.ts';
import { baseURL } from '@/listing-website/lib/content.ts';

import IconMapPin from '@/common/icons/tabler/map-pin.svg';

const RightMenu = () => {
  const [isXlDesktop] = useIsMobile(1024, 1464);

  return (
    <div>
      <a href={`${baseURL}/contact-us`} aria-label={'Locations'} data-testid="mapPin">
        <IconMapPin />
        {!isXlDesktop && <span>Locations</span>}
      </a>
    </div>
  );
};

export default RightMenu;
