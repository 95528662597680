import React from 'react';
import cc from 'classcat';

import styles from './styles.module.scss';

const Button = (
  {
    children,
    type = 'button',
    as,
    size = 'M',
    styleOverride = '',
    noborder = false,
    colour = 'action',
    rounded,
    style,
    LeftIcon,
    RightIcon,
    ...props
  }: ButtonProps,
  ref: React.Ref<HTMLButtonElement | HTMLInputElement>,
) => {
  const Wrapper = props.href ? 'a' : 'button';

  let classNames = cc({
    [styleOverride]: Boolean(styleOverride),
    [styles.button]: true,
    [styles.disabled]: props.disabled,
    [styles.noborder]: noborder,
    [styles.rounded]: rounded,
    [styles.withLeftIcon]: !!LeftIcon,
    [styles.withRightIcon]: !!RightIcon,
  });

  if (type && styles[type]) {
    classNames = cc([classNames, styles[type]]);
  }

  if (size && styles[size]) {
    classNames = cc([classNames, styles[size]]);
  }

  if (colour && styles[colour]) {
    classNames = cc([classNames, styles[colour]]);
  }

  if (style && styles[style]) {
    classNames = cc([classNames, styles[style]]);
  }

  return (
    <Wrapper
      type={as || (props.href ? undefined : type)}
      role="button"
      tabIndex={0}
      className={classNames}
      ref={ref}
      {...(props as any)}
    >
      {LeftIcon && (
        <div className={styles.leftIcon}>
          <LeftIcon />
        </div>
      )}
      {children}
      {RightIcon && (
        <div className={styles.rightIcon}>
          <RightIcon />
        </div>
      )}
    </Wrapper>
  );
};

export default React.forwardRef(Button);
