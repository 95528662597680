import React, { useEffect, useState } from 'react';
import cc from 'classcat';
import { useToggle } from 'react-use';

import useIsMobile from '@/common/hooks/useIsMobile.ts';
import {
  NavLinks,
  aboutUsLinks,
  baseURL,
  fleetFinanceLinks,
  fleetManagementLinks,
  novatedLeasingLinks,
  shopCarsBodies,
  shopCarsLinks,
} from '@/listing-website/lib/content.ts';

import IconChevronDown from '@/common/icons/tabler/chevron-down.svg';

import ShopCarsDropdown from './shopCars.tsx';

import styles from './styles.module.scss';

type AllNavLinks = {
  navName: string;
  links: NavLinks[];
};

type NavigationProps = {
  showDropdown?: boolean;
  openMobileMenu?: boolean;
  onClickAway?: React.MouseEventHandler;
};

const Navigation = ({ showDropdown = true, openMobileMenu = false, onClickAway }: NavigationProps) => {
  const [isMobile, isTablet, isDesktop] = useIsMobile(768, 1024, 1200);
  const [itemMenuCount, setItemMenuCount] = useState<number>(Navigation.overflowIndex);

  const navLinks: AllNavLinks[] = [
    { navName: 'Fleet Management', links: fleetManagementLinks },
    { navName: 'Novated Leasing', links: novatedLeasingLinks },
    { navName: 'Fleet Finance', links: fleetFinanceLinks },
    { navName: 'About Us', links: aboutUsLinks },
  ];
  const navLinksItems = navLinks.map((navLink) => navLink.links).flatMap((nav) => nav);

  useEffect(() => {
    if (showDropdown && !isMobile) {
      let itemCount = Navigation.overflowIndex;

      if (isTablet) {
        itemCount = 1;
      } else if (isDesktop) {
        itemCount = 2;
      } else {
        itemCount = Navigation.overflowIndex;
      }

      setItemMenuCount(itemCount);
    } else {
      setItemMenuCount(navLinksItems.length);
    }
  }, [isMobile, isTablet, isDesktop, showDropdown, navLinksItems.length]);

  const hideNavsLength = navLinks
    .slice(itemMenuCount)
    .map((hideNav) => hideNav.links)
    .flatMap((nav) => nav).length;

  return (
    <>
      <div className={styles.navContainer} data-active={openMobileMenu}>
        <div className={styles.inner} data-testid="headerNavLinks">
          <ShopCarsDropdown links={shopCarsLinks} bodies={shopCarsBodies} />

          {!isMobile &&
            navLinks.slice(0, itemMenuCount).map((nav) => (
              <div key={nav.navName} data-testid="menuLinks">
                <Dropdown headerTitle={nav.navName} menuItems={nav.links} />
              </div>
            ))}

          {showDropdown && <Dropdown headerTitle="Contact Us" link="/contact-us" />}

          {showDropdown && itemMenuCount < navLinks.length && itemMenuCount !== 0 && (
            <Dropdown headerTitle={'More'} menuItems={navLinksItems.slice(navLinksItems.length - hideNavsLength)} />
          )}

          {isMobile &&
            navLinksItems.map((nav) => (
              <div key={nav.title}>
                <a href={nav.link} key={nav.title}>
                  {nav.title}
                </a>
              </div>
            ))}
        </div>
      </div>

      {openMobileMenu && (
        <div
          data-testid="backdrop"
          className={styles.backdrop}
          onClick={onClickAway && onClickAway}
          onKeyDown={onClickAway ? (e) => e.key === 'Escape' && onClickAway : undefined}
          role="presentation"
        />
      )}
    </>
  );
};

type DropdownProps = {
  headerTitle: string;
  menuItems?: NavLinks[];
  link?: string;
};

const Dropdown = ({ headerTitle, menuItems, link }: DropdownProps) => {
  const [isOpen, toggleOpen] = useToggle(false);

  const onClick = () => {
    window.location.href = `${baseURL}${link}`;
  };

  return (
    <div className={cc({ [styles.shopCars]: true, [styles.isOpen]: isOpen })}>
      <button
        onClick={menuItems ? toggleOpen : onClick}
        className={cc({ [styles.shopCarsButton]: true, [styles.isOpen]: isOpen })}
      >
        {headerTitle}
        {menuItems && <IconChevronDown />}
      </button>
      {isOpen && menuItems && (
        <>
          <div className={styles.navList} role={'list'} data-testid="dropdownlist">
            {menuItems?.map((item) => (
              <a key={item.title} href={item.link}>
                {item.title}
              </a>
            ))}
          </div>
          <div
            data-testid="backdrop"
            className={styles.backdrop}
            onClick={toggleOpen}
            onKeyDown={(e) => e.key === 'Escape' && toggleOpen}
            role="presentation"
          />
        </>
      )}
    </div>
  );
};

export default Navigation;

Navigation.overflowIndex = 4;
